import styled from "@emotion/styled";
import { Card, CardContent, CardMedia, css, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";
import { toast } from "react-toastify";

const Panaderia = () => {
  const [handleLoaded] = useOutletContext();
  const [data, setData] = useState(undefined);
  const { get } = helpHttp();

  useEffect(() => {
    get(`categorias/getSubcategorias/1`)
      .then((data) => {
        setData(data.datos);
      })
      .catch(() => toast.error("Ha ocurrido un error"))
      .finally(() => {
        handleLoaded();
      });
  }, []);

  return (
    <GridData>
      {data?.length > 0 &&
        data.map((element) => (
          <StyledCard estado={element.estado} key={element.id}>
            <Link to={element.nombre}>
              <CardMedia
                component="img"
                image={element.icon}
                alt={element.nombre}
              />
              <StyledCardContent>
                <Typography gutterBottom variant="subtitle" fontWeight={700}>
                  {element.nombre}
                </Typography>
              </StyledCardContent>
            </Link>
          </StyledCard>
        ))}
    </GridData>
  );
};

export default Panaderia;

const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  justify-content: center;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
  gap: 10px;
  a {
    text-decoration: none;
    color: inherit;
  }
  > div {
    border-radius: 30px;
    text-align: center;
  }
`;

const StyledCard = styled(Card)`
  padding: 40px 0px;
  height: 100%;
  color: #114c97;
  transition: 0.5s;
  box-shadow: 0px 3px 6px #00000029;
  :hover {
    transition: 0.5s;
    background-color: #114c97;
    color: floralwhite;
    cursor: pointer;
  }
  img {
    height: 100px;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;
const StyledCardContent = styled(CardContent)`
  display: flex;
  height: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
