/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import Resizer from "react-image-file-resizer";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";
import SettingsIcon from "@mui/icons-material/Settings";
import DynamicFields from "./DynamicFields";
import { useUser } from "../../../hooks/useUser";
import { helpHttp } from "../../../helper/helpHttp";
import { SettingsSchema } from "./Schema";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../redux/slices/user";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { toast } from "react-toastify";
import { codigosDeVendedor } from "../../../constant/codes";

const Fields = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(user.foto || undefined);
  const { roles, documento } = DynamicFields();
  const { post } = helpHttp();
  const [filteredCities, setFilteredCities] = useState([]);
  const [states, setStates] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", resolver: yupResolver(SettingsSchema) });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onSubmit = (data) => {
    setLoading(true);
    post("usuarios/editProfile/" + user.id, {
      body: { ...data, foto: avatar !== user.foto && avatar },
    })
      .then((response) => {
        if (response.codigo === "200") {
          dispatch(updateUser(response.datos));
          navigate("/main");
        }
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  const handleAvatar = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    setAvatar(image);
  };

  const handleChangeState = ({ target }) => {
    const { value } = target;
    const foundState = states.find((state) => state.name === value);

    setFilteredCities([]);

    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "VENjRkZ3Rjg0aTl0ckJ1WEVNMmhlMzdlbXg0YUtoWGZiVzc4SXk4Mw=="
    );

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    fetch(
      `https://api.countrystatecity.in/v1/countries/CO/states/${foundState.iso2}/cities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setFilteredCities(result));
  };

  const handleLoadStates = () => {
    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "VENjRkZ3Rjg0aTl0ckJ1WEVNMmhlMzdlbXg0YUtoWGZiVzc4SXk4Mw=="
    );

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      "https://api.countrystatecity.in/v1/countries/CO/states",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        result.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setStates(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (user.departamento && user.departamento !== "") {
      if (states.length) {
        const foundState = states.find(
          (state) => state.name === user.departamento
        );

        handleChangeState({ target: { value: foundState.name } });
      } else {
        handleLoadStates();
      }
    } else {
      handleLoadStates();
    }
  }, [states]);

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledAvatar>
          <div>
            <input
              max-file-size="1024"
              accept="image/jpeg,image/jpg,image/png"
              type="file"
              {...register("foto", { onChange: handleAvatar })}
            />
            <SettingsIcon color="secondary" />
            <img src={avatar ?? "https://via.placeholder.com/150"} alt="img" />
          </div>
          <p>{errors.foto?.message}</p>
          <Divider />
        </StyledAvatar>
        <TextField
          variant="standard"
          error={!!errors.codigo?.message}
          helperText={errors.codigo?.message}
          label="Código"
          readOnly
          {...register("codigo")}
        />
        <TextField
          variant="standard"
          error={!!errors.correo?.message}
          helperText={errors.correo?.message}
          label="Correo electrónico"
          type="email"
          value={user.correo}
          readOnly
          {...register("correo")}
        />
        <TextField
          variant="standard"
          error={!!errors.nombres?.message}
          helperText={errors.nombres?.message}
          label="Nombre"
          value={user.nombres}
          readOnly
          {...register("nombres")}
        />
        <TextField
          variant="standard"
          error={!!errors.apellidos?.message}
          helperText={errors.apellidos?.message}
          value={user.apellidos}
          readOnly
          label="Apellidos"
          {...register("apellidos")}
        />
        <FormControl variant="standard" focused disableScrollLock>
          <InputLabel error={!!errors.tipo_documento?.message}>
            Tipo de documento
          </InputLabel>
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            align="left"
            defaultValue={user.tipo_documento}
            error={!!errors.tipo_documento?.message}
            label="Tipo de documento"
            {...register("tipo_documento")}
          >
            {documento?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nombre}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {errors.tipo_documento?.message}
          </FormHelperText>
        </FormControl>
        <TextField
          autoComplete="no"
          variant="standard"
          focused
          error={!!errors.numero_documento?.message}
          helperText={errors.numero_documento?.message}
          label="Número de documento"
          defaultValue={user.numero_documento}
          {...register("numero_documento")}
        />
        <TextField
          autoComplete="no"
          variant="standard"
          focused
          error={!!errors.celular?.message}
          helperText={errors.celular?.message}
          {...register("celular")}
          defaultValue={user.celular}
          label="Celular"
        />
        <FormControl focused variant="standard">
          <InputLabel error={!!errors.cargo?.message}>Cargo</InputLabel>
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            defaultValue={user.cargo}
            align="left"
            error={!!errors.cargo?.message}
            label="Cargo"
            {...register("cargo")}
          >
            {roles?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nombre}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>{errors.cargo?.message}</FormHelperText>
        </FormControl>
        <FormControl focused variant="standard">
          <InputLabel error={!!errors.departamento?.message}>
            Departamento
          </InputLabel>
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            defaultValue={user.departamento}
            align="left"
            error={!!errors.departamento?.message}
            label="Departamento"
            {...register("departamento")}
            onChange={handleChangeState}
          >
            {!states.length ? (
              <MenuItem value={user.departamento}>{user.departamento}</MenuItem>
            ) : (
              states?.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))
            )}
          </Select>
          <FormHelperText error>{errors.departamento?.message}</FormHelperText>
        </FormControl>
        <FormControl focused variant="standard">
          <InputLabel error={!!errors.ciudad?.message}>Ciudad</InputLabel>
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            defaultValue={user.ciudad}
            align="left"
            error={!!errors.ciudad?.message}
            label="Ciudad"
            {...register("ciudad")}
          >
            {!filteredCities.length ? (
              <MenuItem value={user.ciudad}>{user.ciudad}</MenuItem>
            ) : (
              filteredCities?.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))
            )}
          </Select>
          <FormHelperText error>{errors.cargo?.message}</FormHelperText>
        </FormControl>
        <FormControl focused variant="standard">
          <Autocomplete
            defaultValue={user.ruta}
            disablePortal
            id="combo-box-demo"
            options={codigosDeVendedor}
            sx={{ width: 300 }}
            align="left"
            renderInput={(params) => (
              <TextField
                focused
                {...register("ruta")}
                error={!!errors.ruta?.message}
                {...params}
                label="Código de vendedor"
                variant="standard"
              />
            )}
          />
          <FormHelperText error>{errors.ruta?.message}</FormHelperText>
        </FormControl>
        <br />
        <Typography
          className="close"
          onClick={() => navigate(-1)}
          color="primary"
          variant="link"
        >
          CERRAR
        </Typography>
        <Button color="secondary" type="submit" variant="contained">
          Guardar
        </Button>
      </StyledForm>
      {loading && <Loader />}
    </>
  );
};

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  gap: 15px;
  [readOnly] {
    input {
      color: gray;
    }
    * {
      pointer-events: none;
    }
  }
  p {
    font-size: 1rem;
  }
  .close {
    text-decoration: underline;
    @media (min-width: 768px) {
      justify-self: flex-end;
      align-self: center;
    }
  }
`;

const StyledAvatar = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media (min-width: 768px) {
    grid-column: auto / span 2;
  }
  position: relative;
  > div {
    position: relative;
    width: max-content;
  }
  > p {
    align-self: start;
    color: #d32f2f;
  }
  input {
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    font-size: 0;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 12px;
  }
  img {
    border: 2px solid #114c97;
    border-radius: 12px;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  svg {
    background-color: #114c97;
    border-radius: 50%;
    position: absolute;
    font-size: 1.75rem;
    bottom: 0px;
    right: -5px;
  }
`;

export default Fields;
