import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import Fields from './SignRecoveryFields'

const SignRecoveryForm = () => {
  return (
    <StyledFormContainer>
      <Typography variant='title' component='h2'>
        Restablece la contraseña
      </Typography>
      <Typography color='primary' component='p'>
        Ingresa el correo de registro y recibirás un correo con un enlace para
        restablecer la contraseña
      </Typography>
      <Fields />
    </StyledFormContainer>
  )
}

const StyledFormContainer = styled.div`
  background: #fff;
  max-width: 550px;
  flex: 1;
  padding: 40px 20px;
  border-radius: 10px;
  color: #114c97;
  > h2{margin-bottom: 20px;}
`

export default SignRecoveryForm
