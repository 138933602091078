import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { PasswordChangeSchema } from "./Schema";
import { helpHttp } from "../../../helper/helpHttp";
import ModalResponse from "../../ModalResponse";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Fields = ({ Modalclose }) => {
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(undefined);
  const { post } = helpHttp();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", resolver: yupResolver(PasswordChangeSchema) });

  const onSubmit = (data) => {
    post("usuarios/changePassword", {
      body: { ...data, correo: user.correo },
    })
      .then((response) => {
        setResponse(response);
        setOpen(true);
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  const handleClose = () => {
    response.codigo === "200" && Modalclose();
    setOpen(false);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant={"standard"}
        error={!!errors.password?.message}
        helperText={errors.password?.message}
        label="Contraseña actual"
        {...register("password")}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant={"standard"}
        error={!!errors.newPassword?.message}
        helperText={errors.newPassword?.message}
        label="Cambiar contraseña"
        {...register("newPassword")}
        type={showPassword ? "text" : "password"}
      />
      <TextField
        variant={"standard"}
        error={!!errors.passwordConfirmation?.message}
        helperText={errors.passwordConfirmation?.message}
        label="Confirmar contraseña"
        {...register("passwordConfirmation")}
        type={showPassword ? "text" : "password"}
      />
      <Button color="secondary" type="submit" variant="contained">
        Guardar
      </Button>
      <ModalResponse
        open={open}
        response={response}
        handleClose={handleClose}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  margin-top: 30px;
  gap: 15px;
  flex-direction: column;
  p {
    font-size: 1rem;
  }
  > button {
    margin-top: 30px;
  }
`;

export default Fields;
