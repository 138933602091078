import styled from "@emotion/styled";
import { Container } from "@mui/system";
import React from "react";
import BackArrow from "../../components/BackArrow";

const FormContainer = ({ children }) => {
  return (
    <StyledContainer>
      <Container>
        <StyledMain>
          <StyledImageContainer>
            <BackArrow />
            <img src="/images/login.jpg" alt="Bread" className="login_img" />
          </StyledImageContainer>
          <StyledFormContainer>{children}</StyledFormContainer>
        </StyledMain>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: #e5e5e5;
  min-height: calc(100vh - 64px);
  display: flex;
  align-items: center;
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .wave {
    width: min-content;
    height: 100%;
    position: absolute;
    right: -1px;
  }
  img {
    width: 100%;
    max-width: 600px;
  }
  //First child is Back arrow
  > div {
    position: absolute;
    top: 15px;
    left: 15px;
  }
`;

const StyledFormContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #114c97;
`;

const StyledMain = styled.main`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  margin: 90px 0 20px 0;

  img.login_img {
    margin-top: auto;
    height: 100%;
    min-width: 100%;
    object-fit: contain;
    object-position: bottom;
  }

  @media (width > 991px) {
    margin: 120px 0 50px 0;
    height: 70vh;
    flex-direction: row;
    max-height: 800px;
  }
`;

export default FormContainer;
