const productVideo = {
    "Levadura": null,
    "Premezclas": "https://player.vimeo.com/video/809840035",
    "Margarinas": "https://player.vimeo.com/video/809838338",
    "Cremas": "https://player.vimeo.com/video/809837904",
    "Complementos": null,
}

export const getProductVideo = (product) => {
    if (!product) {
        return null
    }

    return productVideo[product]
}