import styled from "@emotion/styled";
import { Button, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";

const TestResult = ({ calificacion }) => {
  if (calificacion.aprobado) {
    return (
      <StyledDialogTitle component="div">
        <Typography variant="title" color="primary">
          ¡GANASTE!
        </Typography>
        <div>
          <Typography>
            Tus datos serán enviados al área encargada y vía whatsapp
            <br />
            serás contactado para hacer la entrega del premio.
          </Typography>
          <Typography variant="subtitle" fontWeight={700}>
            GRACIAS POR PARTICIPAR
          </Typography>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="secondary"
            size="large"
            variant="contained"
          >
            Cerrar
          </Button>
        </div>
      </StyledDialogTitle>
    );
  }

  return (
    <StyledDialogTitle component="div">
      <Typography variant="title" color="primary">
        PERDISTE
      </Typography>
      <div>
        <Typography>
          No completaste con satisfacción el módulo,
          <br />
          intenta de nuevo el próximo mes
        </Typography>
        <Typography variant="subtitle" fontWeight={700}>
          GRACIAS POR PARTICIPAR
        </Typography>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          color="secondary"
          size="large"
          variant="contained"
        >
          Cerrar
        </Button>
      </div>
    </StyledDialogTitle>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  text-align: center;
`;
export default TestResult;
