import styled from "@emotion/styled";
import React from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const MainLink = ({ returnHome }) => {
  return (
    <StyledBackPage onClick={returnHome} to={"main"}>
      <ArrowBackRoundedIcon color="secondary" />
      <Typography variant="link">Ver menú</Typography>
    </StyledBackPage>
  );
};

export default MainLink;

const StyledBackPage = styled(Link)`
  background-color: #ffffff30;
  padding: 10px;
  border-radius: 10px;
  @media (min-width: 768px) {
    margin-left: 20px;
  }
  display: flex;
  text-decoration: none;
  color: white;
  :hover {
    text-decoration: underline;
  }
  gap: 5px;
  align-items: center;
  cursor: pointer;
  width: max-content;
  > a {
    text-decoration: none;
  }
`;
