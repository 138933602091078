import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
import { useModalQuiz } from "./useModalQuiz";
import { TestFields } from "../../../../components/Forms/Test/TestFields";
import TestResult from "./TestResult";

export const ModalQuiz = ({ modulo, token, onCloseQuiz }) => {
  const {
    handleResponse,
    setReset,
    test,
    calificacion,
    actualQuestion,
    setCalificacion,
    start,
    setStart,
    open,
    setOpen,
  } = useModalQuiz({ token, modulo });

  useEffect(() => {
    setOpen(true);
    setStart(true);
  }, []);

  return (
    <div>
      <Dialog disableScrollLock open={open}>
        {calificacion ? (
          <TestResult calificacion={calificacion} />
        ) : (
          <>
            <StyledDialogTitle component="div">
              <Typography variant="link" color="primary">
                {actualQuestion?.number}/{test?.length}
              </Typography>
              <Typography variant="title" component="h2">
                {modulo?.nombre}
              </Typography>
              <Typography>{actualQuestion?.question?.pregunta}</Typography>
            </StyledDialogTitle>
            <StyledDialogContent>
              <TestFields
                last={actualQuestion?.number === test?.length}
                question={actualQuestion?.question}
                handleResponse={handleResponse}
              />
            </StyledDialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    gap: 20px;
  }
  gap: 10px;
  > h2 {
    font-size: 1.5rem;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  overflow: visible;
`;
