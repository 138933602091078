import styled from "@emotion/styled";
import { Button, TextField, Typography, Link } from "@mui/material";
import React, { useState } from "react";
import { helpHttp } from "../../../helper/helpHttp";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const VerificationCode = ({
  setValidateEmail,
  emailToValidate,
  validateMessage,
}) => {
  const [code, setCode] = useState("");
  const [requestNewCode, setRequestNewCode] = useState(false);

  const { post, get } = helpHttp();
  const navigate = useNavigate();

  const onSubmit = () => {
    const body = {
      correo: emailToValidate,
      codigo: code,
    };

    post("usuarios/registerConfirmation", {
      body,
    })
      .then((response) => {
        if (response.codigo === "200") {
          toast.success("Su correo ha sido confirmado");

          setTimeout(() => {
            navigate("/sign-in", { replace: true });
          }, 3000);
        }

        if (response.codigo === "400") {
          toast.error("El código ingresado no existe o se encuentra expirado.");
          setRequestNewCode(true);
        }
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  const handleRequestNewCode = () => {
    get(`usuarios/sendCodeConfirmation/${emailToValidate}`).then((response) => {
      if (response.codigo === "200") {
        toast.success(
          "Se ha enviado un nuevo código de verificación a su correo"
        );
      }
    });
  };

  return (
    <VerificationCodeStyled>
      <Typography variant="h4" component="b">
        Completa tu registro
      </Typography>
      <Typography variant="h6" color={"black"}>
        {validateMessage}
      </Typography>
      <TextField
        autoComplete="no"
        variant="filled"
        label="Código de verificación"
        onChange={(e) => setCode(e.target.value)}
      />
      <ButtonsContainer>
        <StyledButton
          color="inherit"
          variant="contained"
          onClick={() => setValidateEmail(false)}
        >
          Cancelar
        </StyledButton>
        <StyledButton color="secondary" variant="contained" onClick={onSubmit}>
          Ingresar
        </StyledButton>
      </ButtonsContainer>
      {requestNewCode && (
        <StyledButton
          size="small"
          style={{ margin: "auto" }}
          onClick={handleRequestNewCode}
        >
          Solicitar nuevo código
        </StyledButton>
      )}
    </VerificationCodeStyled>
  );
};

const VerificationCodeStyled = styled.div`
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  height: 450px;
  justify-content: center;
  gap: 50px;
`;

const ButtonsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  justify-self: center;
`;
