import styled from "@emotion/styled";

export const Temarios = styled.div`
  .titles {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      background-color: #f6f7f9;
      padding: 20px 25px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      font-size: 18px;
      font-weight: medium;
      cursor: pointer;

      .MuiSvgIcon-root {
        font-size: 1.8rem;
      }
    }

    .titleDetails {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 30px 50px;
    }

    .titleDetail {
      display: flex;
      gap: 5px;

      .index {
        margin-top: 1px;
      }
    }
  }
`;
