import React from "react";
import ConstructionIcon from "@mui/icons-material/Construction";
import styled from "@emotion/styled";

export const BuildingPage = () => {
  return (
    <Container>
      <ConstructionIcon className="icon" color="secondary" />
      <p>Esta página está en construcción</p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .icon {
    font-size: 75px;
    opacity: 0.5;
  }

  p {
    color: grey;
  }
`;
