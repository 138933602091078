import { Link as ReactLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Fields from "./SignInFields";

const SignInForm = () => {
  return (
    <StyledFormContainer>
      <Typography variant="title" component="h2">
        ¡Bienvenid@!
      </Typography>
      <Typography variant="text" component="p">
        Inicia sesión para continuar
      </Typography>
      <Fields />
      <StyledRegister>
        <Typography>¿No tienes cuenta?</Typography>
        <Link component={ReactLink} variant="text" to="/sign-up">
          Regístrate
        </Link>
      </StyledRegister>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled.div`
  background: #fff;
  max-width: 550px;
  flex: 1;
  padding: 40px 20px;
  border-radius: 10px;
  color: #114c97;
  > p {
    font-size: 20px;
  }
`;

const StyledRegister = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
  > a {
    text-decoration: none;
    font-weight: 600;
    :hover {
      text-decoration: underline;
    }
  }
`;

export default SignInForm;
