import React from "react";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Typography } from "@mui/material";
import { StyledDiv, GridData, FAQ, StyledDialog } from "./styles";
import { Temario } from "./temario/Temario";

const SeminarioInfo = ({ seminario, open, handleClose }) => {
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth={"xl"}
      BackdropProps={{ style: { backgroundColor: "#114C97ca" } }}
      open={open}
    >
      <Typography variant="title" className="cross" onClick={handleClose}>
        x
      </Typography>
      {seminario ? (
        <StyledDiv>
          <div className="info">
            <Typography
              className="subtitle"
              component={"h2"}
              variant="subtitle"
            >
              Información del curso:
            </Typography>
            <div>
              <Typography>
                <WatchLaterOutlinedIcon color="secondary" /> Duracion:{" "}
                {seminario?.duracion} min
              </Typography>
              {seminario?.nivel !== " " && (
                <Typography>
                  <SignalCellularAltOutlinedIcon color="secondary" /> Nivel:{" "}
                  {seminario?.nivel}
                </Typography>
              )}
            </div>
          </div>
          <div className="title">
            <Typography component={"h1"} variant="title">
              {seminario.nombre}
            </Typography>
            <Typography>{seminario.descripcion}</Typography>
          </div>
          {seminario?.aprendizajes?.length > 0 && (
            <div className="details">
              <Typography className="subtitle" variant="subtitle">
                ¿Qué aprenderás en este curso?
              </Typography>
              <GridData>
                {seminario?.aprendizajes?.map((e, index) => (
                  <div key={`learning-${index}`}>
                    <CheckOutlinedIcon color="secondary" />
                    <Typography>{e.item}</Typography>
                  </div>
                ))}
              </GridData>
            </div>
          )}
          {/* habilidades */}
          {seminario?.habilidades?.length > 0 && (
            <div className="details">
              <Typography className="subtitle" variant="subtitle">
                Habilidades que voy aprender
              </Typography>
              <GridData>
                {seminario?.habilidades?.map((e, index) => (
                  <div key={`Skill-${index}`}>
                    <CheckOutlinedIcon color="secondary" />
                    <Typography>{e.item}</Typography>
                  </div>
                ))}
              </GridData>
            </div>
          )}
          {/* preguntas frecuentes */}
          {seminario?.preguntasFrecuentes?.length > 0 && (
            <div className="details">
              <Typography className="subtitle" variant="subtitle">
                Preguntas frecuentes
              </Typography>
              <FAQ>
                {seminario?.preguntasFrecuentes?.map((e, index) => (
                  <div className="FAQ" key={`FAQ-${index}`}>
                    <Typography>{e.pregunta}</Typography>
                    <Typography>{e.respuesta}</Typography>
                  </div>
                ))}
              </FAQ>
            </div>
          )}
          {/* temario */}
          {seminario?.temario?.length > 0 && (
            <div className="details">
              <Temario temario={seminario?.temario} />
            </div>
          )}
        </StyledDiv>
      ) : (
        <Typography component={"h2"} className="error" variant="title">
          No se ha encontrado informacion del seminario intentelo mas tarde
        </Typography>
      )}
    </StyledDialog>
  );
};

export default SeminarioInfo;
