export const images = [
  {
    url: "./images/home/banner--001.jpg",
    title: "DEMOSTRACIONES",
    subtitle: "de producto",
  },
  {
    url: "./images/home/banner--002.jpg",
    title: "CAPACITACIONES",
    subtitle: "por expertos",
    style: {
      objectPosition: "center top",
    },
  },
  {
    url: "./images/home/banner--003.jpg",
    title: "FORMACIÓN",
    subtitle: "en Panadería y Pastelería",
  },
];
