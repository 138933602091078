import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: undefined,
  activeCategory: undefined,
  loadingData: false,
  activeSubCategory: undefined,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    changeCategory: (state, action) => {
      state.activeCategory = action.payload;
      state.loadingData = true;
    },
    changeSubCategory: (state, action) => {
      state.activeSubCategory = action.payload;
      state.activeSubCategory === state.activeCategory
        ? (state.loadingData = 1)
        : (state.loadingData = true);
      // Si categoria es igual que subcategoria empieza en 1 porque el banner no se volvera a cargar
    },
    changeLoading: (state) => {
      if (state.loadingData === 1) {
        state.loadingData = false;
      }
      if (state.loadingData === true) {
        state.loadingData = 1;
      }
    },
    reset: (state) => {
      state.activeCategory = undefined;
      state.activeSubCategory = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeCategory,
  changeSubCategory,
  changeLoading,
  setCategories,
  reset,
} = categorySlice.actions;

export default categorySlice.reducer;
