import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const SignUpSchema = Yup.object({
  nombres: Yup.string().required("Requerido"),
  apellidos: Yup.string().required("Requerido"),
  tipo_documento: Yup.string().required("Requerido"),
  numero_documento: Yup.string().required("Requerido"),
  cargo: Yup.string().required("Requerido"),
  celular: Yup.string()
    .required("Requerido")
    .matches(phoneRegExp, "Número de telefono no valido"),
  departamento: Yup.string().required("Requerido"),
  ciudad: Yup.string().required("Requerido"),
  ruta: Yup.string(),
  correo: Yup.string()
    .email("Correo electrónico invalido")
    .required("Requerido"),
  password: Yup.string().required("Requerido"),
  emailConfirmation: Yup.string()
    .required("Requerido")
    .oneOf([Yup.ref("correo"), null], "El correo electrónico no coincide"),
  passwordConfirmation: Yup.string()
    .required("Requerido")
    .oneOf([Yup.ref("password"), null], "La contraseña no coincide"),
  terms: Yup.boolean().oneOf([true], "Debe aceptar los términos y condiciones"),
});
