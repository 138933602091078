import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

export const StyledDiv = styled.div`
  @media (min-width: 768px) {
    padding: 50px;
  }
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .info {
    padding: 15px;
    background-color: #f6f7f9;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 50px;
      > p {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 15px;
    > h1 {
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .subtitle {
    color: #114c97;
    font-size: 20px;
    @media (min-width: 768px) {
      font-size: 30px;
    }
    font-weight: 700;
  }
`;
export const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 30px;
  > div {
    display: flex;
    gap: 10px;
    > svg {
      font-size: 30px;
    }
  }
`;

export const FAQ = styled.div`
  display:flex;
  flex-direction:column;
  gap: 10px;
  margin-bottom: 20px;

  .FAQ{
    background-color: #f6f7f9;
    padding: 20px 45px;
    border-radius: 20px;
    display:flex;
    flex-direction:column;
    gap:10px;
  }
`

export const StyledDialog = styled(Dialog)`
  .cross {
    cursor: pointer;
    font-size: 30px;
    line-height: 0.25;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .error{
    padding: 40px;
    @media (max-width: 768px) {
        font-size: 26px;
      }
  }
`;
