import styled from "@emotion/styled";
import { Menu, Tooltip } from "@mui/material";
import React, { useState } from "react";
import useWindowDimensions from "../../../hooks/useWidth";
import { LoggedLinks } from "../../Menu/Links";

const Avatar = ({ user, logOutUser }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const width = useWindowDimensions();

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  return (
    <ImageContainer>
      {width >= 768 ? (
        <>
          <Tooltip title="Opciones">
            <img
              onClick={handleOpenUserMenu}
              src={user.foto ?? "https://via.placeholder.com/150"}
              alt="avatar"
              id="here"
            />
          </Tooltip>
          <StyledMenu
            disableScrollLock
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <LoggedLinks mobile logOutUser={logOutUser} />
          </StyledMenu>
        </>
      ) : (
        <img
          src={user.foto ?? "https://via.placeholder.com/150"}
          alt="avatar"
          id="here2"
        />
      )}
    </ImageContainer>
  );
};
const StyledMenu = styled(Menu)`
  ul {
    padding: 0;
  }
`;
const ImageContainer = styled.div`
  height: 70px;
  width: 70px;
  @media (min-width: 768px) {
    height: 80px;
    width: 80px;
    cursor: pointer;
  }
  img {
    aspect-ratio: 1;
    height: 100%;
    object-fit: cover;
  }
`;

export default Avatar;
