import styled from "@emotion/styled";
import { Button, CardMedia, Container, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { helpHttp } from "../../helper/helpHttp";
import { useSelector } from "react-redux";
import { Recurrents } from "../Recurrents/Recurrents";
import { UnauthorizedAlert } from "../UnauthorizedAlert/UnauthorizedAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "@mui/material";

const Introduction = () => {
  const { get } = helpHttp();
  const [recurrents, setRecurrents] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [redirected, setRedirected] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/sign-in", { state: location.state });
    location.state = null;
  };

  const handleSignUp = () => {
    navigate("/sign-up", { state: location.state });
    location.state = null;
  };

  useEffect(() => {
    get("recurrents/getRecurrents")
      .then((response) => {
        setRecurrents(response.datos);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (location.state) {
      setRedirected(true);
    }
  }, [location.state]);

  return (
    <Container>
      <StyledContainer>
        <Dialog open={redirected}>
          <Typography
            variant="title"
            sx={CrossSX}
            onClick={() => {
              setRedirected(false);
              location.state = null;
            }}
          >
            x
          </Typography>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Typography variant="title" fontSize={"1.5rem"}>
              ¿Quieres ver nuestro contenido?
            </Typography>

            <Typography fontSize={"1.1rem"}>
              Inicia sesión o regístrate para ver todos nuestros videos
              educativos, seminarios y aprender nuestras recetas.
            </Typography>
            <div style={{ display: "flex", gap: "5px" }}>
              <Button
                onClick={handleLogin}
                size="small"
                variant="contained"
                color="secondary"
              >
                Iniciar sesión
              </Button>
              <Button onClick={handleSignUp} size="small" variant="contained">
                Registrarse
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <StyledVideo>
          <iframe
            width="100%"
            height="100%"
            src="https://player.vimeo.com/video/679766408"
            frameBorder="0"
            allow="fullscreen; picture-in-picture"
            title="Premezclas Introducci&amp;amp;oacute;n"
            loading="lazy"
          />
        </StyledVideo>
        <StyledArticle>
          <Typography variant="title" component="h2">
            ¿Qué es Fleischmann Virtual?
          </Typography>
          <Typography variant={"text"}>
            Fleischmann Virtual, es una plataforma de formación gratuita en
            donde los panaderos y pasteleros pueden complementar sus
            conocimientos técnicos y de negocio con la ayuda de nuestros
            expertos.
          </Typography>
          <Typography variant={"text"}>
            Desde Fleischmann Virtual puedes encontrar información como:
            capacitaciones en vivo, asesorías de producto y de negocio,
            tendencias, técnicas en preparación y tips. Todo en un solo lugar y
            disponible cuando quieras!.
          </Typography>
          <Typography variant={"text"}>
            ¡Todo en un solo lugar y disponible cuando quieras!
          </Typography>
        </StyledArticle>
      </StyledContainer>
      {user.id ? <Recurrents data={recurrents} /> : <UnauthorizedAlert />}
    </Container>
  );
};

const StyledContainer = styled.div`
  margin: 50px auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  max-width: 640px;
  @media (min-width: 1100px) {
    flex-direction: row;
    max-width: 1250px;
  }
`;

const RecurrentsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;
  padding-top: 10px;
  padding-bottom: 70px;
  flex-wrap: wrap;

  .recurrentContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .card {
    margin-top: 10px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 300px;
    height: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
    cursor: pointer;
  }

  .card .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000000;
    padding: 10px;
    transition: 0.5s;
    user-select: none;
    :hover {
      background-color: #00000040;
    }
  }
`;

const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledVideo = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  /* max-height: 400px; */
`;

const CrossSX = {
  cursor: "pointer",
  fontSize: "30px",
  lineHeight: 0.25,
  padding: "10px",
  position: "absolute",
  right: 0,
  top: 0,
};

export default Introduction;
