export const eliminarDiacriticosEs = (texto, noSpaces = false) => {
  let text = texto
    .normalize("NFD")
    .replace(
      /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
      "$1"
    )
    .normalize();

  if (noSpaces) {
    text = text.replaceAll(" ", "");
  }

  return text;
};
