import * as Yup from 'yup'

export const SignValidateSchema = Yup.object({
  correo: Yup.string()
    .email('Correo electrónico invalido')
    .required('Requerido'),
  newPassword: Yup.string().required('Requerido'),
  passwordConfirmation: Yup.string()
    .required('Requerido')
    .oneOf([Yup.ref('newPassword'), null], 'La contraseña no coincide'),
  codigo: Yup.string().required('Requerido')
})
