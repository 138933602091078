import { logOut } from "../redux/slices/user";
import { store } from "../redux/store";

export const helpHttp = () => {
  const BASE_URL = "https://api.escuelaenlineafleischmann.com/";

  const customFetch = async (url, options = {}) => {
    const token = localStorage.getItem("token");

    options.body = JSON.stringify(options.body) || false;
    if (!options.body) delete options.body;

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: token,
      };
    }

    const controller = new AbortController();
    options.signal = controller.signal;
    setTimeout(() => {
      return controller.abort();
    }, 15000);

    const response = await fetch(`${BASE_URL}${url}`, options);
    if (response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        throw new Error("No se pudo refrescar el token");
      }

      const refreshResponse = await fetch(`${BASE_URL}/usuarios/refreshtoken`, {
        method: "POST",
        body: JSON.stringify({ refreshToken }),
      });

      if (!refreshResponse.ok) {
        store.dispatch(logOut());
        throw new Error("No se pudo refrescar el token");
      }

      const response = await refreshResponse.json();
      const newToken = response.datos.token;
      localStorage.setItem("token", newToken);

      const retryOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: newToken,
        },
      };

      return request(url, retryOptions);
    }

    if (!response.ok) {
      const { message } = await response.json();
      throw new Error(message);
    }

    return response.json();
  };

  const get = (url, options = {}) => {
    return customFetch(url, options);
  };

  const post = (url, options = {}) => {
    options.method = "POST";
    return customFetch(url, options);
  };

  const put = (url, options = {}) => {
    options.method = "PUT";
    return customFetch(url, options);
  };

  const del = (url, options = {}) => {
    options.method = "DELETE";
    return customFetch(url, options);
  };

  return {
    get,
    post,
    put,
    del,
  };
};
