import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SignInSchema } from "./Schema";
import { helpHttp } from "../../../helper/helpHttp";
import { useUser } from "../../../hooks/useUser";
import ModalResponse from "../../ModalResponse";
import { toast } from "react-toastify";

const Fields = () => {
  const { logInUser } = useUser();
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(undefined);
  const { post } = helpHttp();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", resolver: yupResolver(SignInSchema) });

  const onSubmit = (data) => {
    post("usuarios/getUsuarioByEmailAndPassword", {
      body: data,
    })
      .then((response) => {
        response.codigo === "200" && logInUser(response.datos);
        setResponse(response);
        setOpen(true);
        if (location.state) {
          navigate(location.state?.from?.pathname);
        }
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField
        autoComplete="off"
        error={!!errors.correo?.message}
        helperText={errors.correo?.message}
        label="Correo electrónico"
        type="email"
        variant="standard"
        {...register("correo")}
      />
      <TextField
        autoComplete="off"
        error={!!errors.password?.message}
        helperText={errors.password?.message}
        label="Contraseña"
        {...register("password")}
        type={showPassword ? "text" : "password"}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                edge="end"
                // color="primary"
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <StyledFormOptions>
        <Link component={ReactLink} variant="text" to="/sign-recovery">
          He olvidado mi contraseña
        </Link>
      </StyledFormOptions>
      <StyledButton color="secondary" type="submit" variant="contained">
        ingresar
      </StyledButton>
      <ModalResponse
        open={open}
        response={response}
        handleClose={handleClose}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  flex-direction: column;
  p {
    font-size: 1rem;
  }
`;

const StyledFormOptions = styled.div`
  margin: 15px 0;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  align-self: center;
`;

export default Fields;
