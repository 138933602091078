import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Button, CardMedia, Container, Typography } from "@mui/material";

export const UnauthorizedAlert = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/sign-in");
  };

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  return (
    <StyledContainer>
      <Typography variant="subtitle1" component="h2">
        <b onClick={handleLogin}>Inicia sesión</b> o{" "}
        <b onClick={handleSignUp}>regístrate</b> para ver más información
      </Typography>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  height: 70px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;

  b {
    color: #114c97;
    cursor: pointer;
  }
`;
