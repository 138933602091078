import styled from "@emotion/styled";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

const MainLogged = () => {
  const { categories } = useSelector((state) => state.category);
  const { user } = useUser();

  return (
    <StyledContainer>
      <StyledHero img="/images/banner--welcome.jpg">
        <MainContainer>
          <StyledTitle>
            <Typography variant="title" component="h2" color="primary">
              Bienvend@
            </Typography>
            <Typography variant="title" component="h2" color="primary">
              {user.nombres} {user.apellidos}
            </Typography>
          </StyledTitle>
          <StyledSubTitle>
            <Typography variant="subtitle" component="h3" color="primary">
              Escuela virtual de
            </Typography>
            <Typography variant="subtitle" component="h3" color="primary">
              panadería y pastelería
            </Typography>
          </StyledSubTitle>
        </MainContainer>
      </StyledHero>
      <Container>
        <GridData>
          {categories &&
            categories.map((element) => (
              <StyledCard key={element.id}>
                <Link to={element.nombre}>
                  <StyledDiv>
                    <CardMedia
                      component="img"
                      image={element.icon}
                      alt={element.nombre}
                    />
                    <Typography gutterBottom variant="link">
                      {element.nombre}
                    </Typography>
                  </StyledDiv>
                  <CardContent>
                    <Typography variant="body">
                      {element.descripcion}
                    </Typography>
                  </CardContent>
                </Link>
              </StyledCard>
            ))}
        </GridData>
      </Container>
    </StyledContainer>
  );
};
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  > img {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }
  > span {
    text-decoration: none;
    font-size: 1.125rem;
  }
`;
const StyledCard = styled(Card)`
  padding: 20px;
  color: #114c97;
  transition: 0.5s;
  box-shadow: 0px 3px 6px #00000029;
  a {
    text-decoration: none;
    color: inherit;
  }

  :hover {
    transition: 0.5s;
    background-color: #114c97;
    color: floralwhite;
    cursor: pointer;
  }
  /* :last-child {grid-column: span 2 ;} */
`;
const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 40px;
  }
  > div {
    border-radius: 30px;
    text-align: center;
  }
  padding: 50px 0;
`;
const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (orientation: landscape) {
    padding-top: 50px;
  }
  h2 {
    margin: 0;
    line-height: 1;
    font-weight: bold;
    font-size: 2.5rem;
    @media (min-width: 768px) {
      font-size: 4rem;
    }
  }
  h3 {
    margin: 0;
    line-height: 1;
    font-size: 1.5rem;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
`;
const StyledWave = styled.img`
  position: absolute;
  bottom: 0;
  width: 110%;
  left: 0;
`;
const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
`;
const StyledSubTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledHero = styled.div`
  position: relative;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.8),
      rgba(0, 0, 0, 0)
    ),
    url(${(props) => props.img});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  overflow: hidden;
  height: 70vh;
  max-height: 650px;
  @media (orientation: landscape) {
    height: 90vh;
  }
`;
const StyledContainer = styled.div`
  min-height: 100vh;
`;
export default MainLogged;
