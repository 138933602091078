export const codigosDeVendedorC = [
  {
    id: 0,
    label: "No lo conozco",
    value: "No lo conozco",
  },
  {
    id: 1,
    label: "C1",
    value: "C1",
  },
  {
    id: 2,
    label: "C2",
    value: "C2",
  },
  {
    id: 3,
    label: "C3",
    value: "C3",
  },
  {
    id: 4,
    label: "C4",
    value: "C4",
  },
  {
    id: 5,
    label: "C5",
    value: "C5",
  },
  {
    id: 6,
    label: "C6",
    value: "C6",
  },
  {
    id: 7,
    label: "C7",
    value: "C7",
  },
  {
    id: 8,
    label: "C8",
    value: "C8",
  },
  {
    id: 9,
    label: "C9",
    value: "C9",
  },
  {
    id: 10,
    label: "C10",
    value: "C10",
  },
  {
    id: 11,
    label: "C11",
    value: "C11",
  },
  {
    id: 12,
    label: "C12",
    value: "C12",
  },
  {
    id: 13,
    label: "C13",
    value: "C13",
  },
  {
    id: 14,
    label: "C14",
    value: "C14",
  },
  {
    id: 15,
    label: "C15",
    value: "C15",
  },
  {
    id: 16,
    label: "C16",
    value: "C16",
  },
  {
    id: 17,
    label: "C17",
    value: "C17",
  },
  {
    id: 18,
    label: "C18",
    value: "C18",
  },
  {
    id: 19,
    label: "C19",
    value: "C19",
  },
  {
    id: 20,
    label: "C20",
    value: "C20",
  },
  {
    id: 21,
    label: "C21",
    value: "C21",
  },
  {
    id: 22,
    label: "C22",
    value: "C22",
  },
  {
    id: 23,
    label: "C23",
    value: "C23",
  },
  {
    id: 24,
    label: "C24",
    value: "C24",
  },
  {
    id: 25,
    label: "C25",
    value: "C25",
  },
  {
    id: 26,
    label: "C26",
    value: "C26",
  },
  {
    id: 27,
    label: "C27",
    value: "C27",
  },
  {
    id: 28,
    label: "C28",
    value: "C28",
  },
  {
    id: 29,
    label: "C29",
    value: "C29",
  },
  {
    id: 30,
    label: "C30",
    value: "C30",
  },
  {
    id: 31,
    label: "C31",
    value: "C31",
  },
  {
    id: 32,
    label: "C32",
    value: "C32",
  },
  {
    id: 33,
    label: "C33",
    value: "C33",
  },
  {
    id: 34,
    label: "C34",
    value: "C34",
  },
  {
    id: 35,
    label: "C35",
    value: "C35",
  },
  {
    id: 36,
    label: "C36",
    value: "C36",
  },
  {
    id: 37,
    label: "C37",
    value: "C37",
  },
  {
    id: 38,
    label: "C38",
    value: "C38",
  },
  {
    id: 39,
    label: "C39",
    value: "C39",
  },
  {
    id: 40,
    label: "C40",
    value: "C40",
  },
  {
    id: 41,
    label: "C41",
    value: "C41",
  },
  {
    id: 42,
    label: "C42",
    value: "C42",
  },
  {
    id: 43,
    label: "C43",
    value: "C43",
  },
  {
    id: 44,
    label: "C44",
    value: "C44",
  },
  {
    id: 45,
    label: "C45",
    value: "C45",
  },
  {
    id: 46,
    label: "C46",
    value: "C46",
  },
  {
    id: 47,
    label: "C47",
    value: "C47",
  },
  {
    id: 48,
    label: "C48",
    value: "C48",
  },
  {
    id: 49,
    label: "C49",
    value: "C49",
  },
  {
    id: 50,
    label: "C50",
    value: "C50",
  },
  {
    id: 51,
    label: "C51",
    value: "C51",
  },
  {
    id: 52,
    label: "C52",
    value: "C52",
  },
  {
    id: 53,
    label: "C53",
    value: "C53",
  },
  {
    id: 54,
    label: "C54",
    value: "C54",
  },
  {
    id: 55,
    label: "C55",
    value: "C55",
  },
  {
    id: 56,
    label: "C56",
    value: "C56",
  },
  {
    id: 57,
    label: "C57",
    value: "C57",
  },
  {
    id: 58,
    label: "C58",
    value: "C58",
  },
  {
    id: 59,
    label: "C59",
    value: "C59",
  },
  {
    id: 60,
    label: "C60",
    value: "C60",
  },
  {
    id: 61,
    label: "C61",
    value: "C61",
  },
  {
    id: 62,
    label: "C62",
    value: "C62",
  },
  {
    id: 63,
    label: "C63",
    value: "C63",
  },
  {
    id: 64,
    label: "C64",
    value: "C64",
  },
  {
    id: 65,
    label: "C65",
    value: "C65",
  },
  {
    id: 66,
    label: "C66",
    value: "C66",
  },
  {
    id: 67,
    label: "C67",
    value: "C67",
  },
  {
    id: 68,
    label: "C68",
    value: "C68",
  },
  {
    id: 69,
    label: "C69",
    value: "C69",
  },
  {
    id: 70,
    label: "C70",
    value: "C70",
  },
  {
    id: 71,
    label: "C71",
    value: "C71",
  },
  {
    id: 72,
    label: "C72",
    value: "C72",
  },
  {
    id: 73,
    label: "C73",
    value: "C73",
  },
  {
    id: 74,
    label: "C74",
    value: "C74",
  },
  {
    id: 75,
    label: "C75",
    value: "C75",
  },
  {
    id: 76,
    label: "C76",
    value: "C76",
  },
  {
    id: 77,
    label: "C77",
    value: "C77",
  },
  {
    id: 78,
    label: "C78",
    value: "C78",
  },
  {
    id: 79,
    label: "C79",
    value: "C79",
  },
  {
    id: 80,
    label: "C80",
    value: "C80",
  },
  {
    id: 81,
    label: "C81",
    value: "C81",
  },
  {
    id: 82,
    label: "C82",
    value: "C82",
  },
  {
    id: 83,
    label: "C83",
    value: "C83",
  },
  {
    id: 84,
    label: "C84",
    value: "C84",
  },
  {
    id: 85,
    label: "C85",
    value: "C85",
  },
  {
    id: 86,
    label: "C86",
    value: "C86",
  },
  {
    id: 87,
    label: "C87",
    value: "C87",
  },
  {
    id: 88,
    label: "C88",
    value: "C88",
  },
  {
    id: 89,
    label: "C89",
    value: "C89",
  },
  {
    id: 90,
    label: "C90",
    value: "C90",
  },
  {
    id: 91,
    label: "C91",
    value: "C91",
  },
  {
    id: 92,
    label: "C92",
    value: "C92",
  },
  {
    id: 93,
    label: "C93",
    value: "C93",
  },
  {
    id: 94,
    label: "C94",
    value: "C94",
  },
  {
    id: 95,
    label: "C95",
    value: "C95",
  },
  {
    id: 96,
    label: "C96",
    value: "C96",
  },
  {
    id: 97,
    label: "C97",
    value: "C97",
  },
  {
    id: 98,
    label: "C98",
    value: "C98",
  },
  {
    id: 99,
    label: "C99",
    value: "C99",
  },
  {
    id: 100,
    label: "C100",
    value: "C100",
  },
];
const codigosDeVendedorT = [
  {
    id: 101,
    label: "T0",
    value: "T0",
  },
  {
    id: 102,
    label: "T1",
    value: "T1",
  },
  {
    id: 103,
    label: "T2",
    value: "T2",
  },
  {
    id: 104,
    label: "T3",
    value: "T3",
  },
  {
    id: 105,
    label: "T4",
    value: "T4",
  },
  {
    id: 106,
    label: "T5",
    value: "T5",
  },
  {
    id: 107,
    label: "T6",
    value: "T6",
  },
  {
    id: 108,
    label: "T7",
    value: "T7",
  },
  {
    id: 109,
    label: "T8",
    value: "T8",
  },
  {
    id: 110,
    label: "T9",
    value: "T9",
  },
  {
    id: 111,
    label: "T10",
    value: "T10",
  },
  {
    id: 112,
    label: "T11",
    value: "T11",
  },
  {
    id: 113,
    label: "T12",
    value: "T12",
  },
  {
    id: 114,
    label: "T13",
    value: "T13",
  },
  {
    id: 115,
    label: "T14",
    value: "T14",
  },
  {
    id: 116,
    label: "T15",
    value: "T15",
  },
];

const codigosDeVendedorDT = [
  {
    id: 117,
    label: "DT1",
    value: "DT1",
  },
  {
    id: 118,
    label: "DT2",
    value: "DT2",
  },
  {
    id: 119,
    label: "DT3",
    value: "DT3",
  },
  {
    id: 120,
    label: "DT4",
    value: "DT4",
  },
  {
    id: 121,
    label: "DT5",
    value: "DT5",
  },
  {
    id: 122,
    label: "DT6",
    value: "DT6",
  },
  {
    id: 123,
    label: "DT7",
    value: "DT7",
  },
  {
    id: 124,
    label: "DT8",
    value: "DT8",
  },
  {
    id: 125,
    label: "DT9",
    value: "DT9",
  },
  {
    id: 126,
    label: "DT10",
    value: "DT10",
  },
  {
    id: 127,
    label: "DT11",
    value: "DT11",
  },
  {
    id: 128,
    label: "DT12",
    value: "DT12",
  },
  {
    id: 129,
    label: "DT13",
    value: "DT13",
  },
  {
    id: 130,
    label: "DT14",
    value: "DT14",
  },
  {
    id: 131,
    label: "DT15",
    value: "DT15",
  },
  {
    id: 132,
    label: "DT16",
    value: "DT16",
  },
  {
    id: 133,
    label: "DT17",
    value: "DT17",
  },
  {
    id: 134,
    label: "DT18",
    value: "DT18",
  },
  {
    id: 135,
    label: "DT19",
    value: "DT19",
  },
  {
    id: 136,
    label: "DT20",
    value: "DT20",
  },
];

export const codigosDeVendedor = codigosDeVendedorC.concat(
  codigosDeVendedorT,
  codigosDeVendedorDT
);
