import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Temarios } from "./styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const Temario = ({ temario = [] }) => {
  const [activeItems, setActiveItems] = useState([]);

  const handleClick = (nombre) => {
    if (!activeItems.includes(nombre)) {
      setActiveItems((val) => val.concat(nombre));
    } else {
      setActiveItems((val) => val.filter((name) => name !== nombre));
    }
  };

  return (
    <Temarios>
      <Typography className="subtitle" variant="subtitle">
        Temario
      </Typography>
      <div className="titles">
        {temario.map((e, index) => (
          <div key={index}>
            <div className="title" onClick={() => handleClick(e.nombre)}>
              <ExpandLessIcon
                sx={{
                  rotate: activeItems.includes(e.nombre) ? "0deg" : "180deg",
                }}
                color="secondary"
              />
              <Typography key={index}>{e.nombre}</Typography>
            </div>
            {activeItems.includes(e.nombre) && (
              <div className="titleDetails">
                {e?.detalles?.map((e, index) => (
                  <div key={`deatil-${index}`} className="titleDetail">
                    <p className="index">{index.toString()}.</p>
                    <Typography>{e.item}</Typography>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </Temarios>
  );
};
