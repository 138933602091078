import { useEffect, useState } from "react";
import { helpHttp } from "../../../helper/helpHttp";
import { toast } from "react-toastify";

const DynamicFields = () => {
  const { get } = helpHttp();
  const [roles, setRoles] = useState(undefined);
  const [documento, setDocumento] = useState(undefined);

  useEffect(() => {
    getRoles();
    getDocumento();
  }, []);

  const getRoles = () => {
    get(`catalogos/getAllJob`)
      .then((data) => setRoles(data.datos))
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  const getDocumento = () => {
    get(`catalogos/getAllDocumentType`)
      .then((data) => setDocumento(data.datos))
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  return { roles, documento };
};

export default DynamicFields;
