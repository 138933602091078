/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";
import { helpHttp } from "../../../helper/helpHttp";
import { SignRecoverySchema } from "./Schema";
import { useNavigate } from "react-router-dom";
import ModalResponse from "../../ModalResponse";
import { toast } from "react-toastify";

const Fields = () => {
  const { get } = helpHttp();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(undefined);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", resolver: yupResolver(SignRecoverySchema) });

  const onSubmit = ({ correo }) => {
    get(`usuarios/restore/${correo}`)
      .then((response) => {
        response.codigo === "200" &&
          navigate("../sign-validate", { replace: true });
        setResponse(response);
        setOpen(true);
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField
        autoComplete="off"
        error={!!errors.correo?.message}
        helperText={errors.correo?.message}
        label="Correo electrónico"
        variant="standard"
        type="email"
        {...register("correo")}
      />
      <StyledButton color="secondary" type="submit" variant="contained">
        Enviar
      </StyledButton>
      <ModalResponse
        open={open}
        response={response}
        handleClose={handleClose}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  margin-top: 20px;
  gap: 15px;
  flex-direction: column;
  p {
    font-size: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  align-self: center;
`;

export default Fields;
