import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'blog-fleischmann'

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken: 'MC5ZYXBuR1JNQUFDMEF5UWM4.VO-_vSNHRjfvv70eZQkJDO-_vW50XSvvv71XVXo477-977-9Ae-_vUzvv73vv73vv70_Ow',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
 
})