import { useEffect, useRef, useState } from 'react'
export const useCountDown = (initialValue, onComplete, restartValues) => {
  const [counter, setCounter] = useState(initialValue)
  const [timer, setTimer] = useState(null)
  const intervalRef = useRef()
  intervalRef.current = counter
  const interrupt = () => {
    clearInterval(timer)
  }

  useEffect(() => {
    setCounter(initialValue)
    const _timer = setInterval(() => {
      if (intervalRef.current === 0) {
        onComplete()
        clearInterval(_timer)
      } else {
        setCounter(intervalRef.current - 1)
      }
    }, 1000)
    setTimer(_timer)
    return () => clearInterval(_timer)
  }, [restartValues]) // trigger new timer

  return { counter, interrupt } // return interrupt
}
