import styled from "@emotion/styled";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import BackArrow from "../BackArrow";

const CategoryContainer = ({ children, showPresenciales }) => {
  const [openPresenciales, setOpenPresenciales] = useState(false);

  return (
    <StyledData>
      <BackArrow />
      {showPresenciales === true && (
        <Button
          onClick={() => {
            setOpenPresenciales(true);
          }}
          color="secondary"
          variant="contained"
          sx={PresencialesBtn}
        >
          Presenciales
        </Button>
      )}

      {children}

      <Dialog open={openPresenciales} sx={DialogSX}>
        <Typography
          variant="title"
          sx={CrossSX}
          onClick={() => {
            setOpenPresenciales(false);
          }}
        >
          x
        </Typography>
        <DialogContent>
          <img style={{ width: "100%" }} src={"/images/presenciales.jpg"} />
        </DialogContent>
      </Dialog>
    </StyledData>
  );
};

export default CategoryContainer;

const DialogSX = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiPaper-root": {
    /*   maxWidth: "calc(100vw - 400px)", */
  },
};

const CrossSX = {
  cursor: "pointer",
  fontSize: "30px",
  lineHeight: 0.25,
  padding: "10px",
  position: "absolute",
  right: 0,
  top: 0,
};

const PresencialesBtn = {
  fontSize: "14px",
  textTransform: "capitalize",
  position: "absolute",
  right: "15px",
  top: "15px",
};

const StyledData = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #f7f7f7;
  padding: 50px 10px 10px 10px;
  @media (min-width: 768px) {
    padding: 50px 30px;
  }
  //First child is Back arrow
  .arrow {
    position: absolute;
    top: 15px;
    left: 15px;
  }
`;
