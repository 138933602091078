import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";
import { urlToId } from "../../utils/urlToId";
import { toast } from "react-toastify";

const CategoryBanner = ({ handleLoaded }) => {
  const [banner, setBanner] = useState(undefined);
  const Params = useParams();
  const URL = Params["*"].split("/");
  const activeCategory = urlToId(URL[URL.length - 1]);
  const { get } = helpHttp();
  useEffect(() => {
    activeCategory !== banner?.id &&
      get(`categorias/getCategoriaById/${activeCategory}`)
        .then((data) => {
          setBanner(data.datos);
        })
        .catch(() => toast.error("Ha ocurrido un error"))
        .finally(() => {
          handleLoaded();
        });
  }, [Params]);

  return (
    <>
      {banner && (
        <StyledBanner img={banner.imagen}>
          <StyledBannerInfo>
            <Typography className="Title" variant="bannerTitle" component="h2">
              {banner.nombre}
            </Typography>
            <Typography variant="bannerSubTitle" component="h3">
              {banner.descripcion}
            </Typography>
          </StyledBannerInfo>
        </StyledBanner>
      )}
    </>
  );
};
const StyledBanner = styled.div`
  min-height: 250px;
  border-radius: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const StyledBannerInfo = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  > h2 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 2.25rem;
    text-shadow: 1px 1px 10px #00000070;
    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }
  > h3 {
    text-align: center;
    font-size: 1.25rem;
    text-shadow: 1px 1px 7px black;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
  .icon {
    width: 80px;
  }
  .Title {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export default memo(CategoryBanner);
