import styled from "@emotion/styled";

const FBComments = () => {

    const $fbRoot = document.querySelector('#fb-root') || document.createElement('div');
    $fbRoot.id = 'fb-root';

    if (!$fbRoot.isConnected) {

        document.body.insertBefore($fbRoot, document.querySelector('body > *'));
    }

    const $fbScript = document.querySelector('#fb-script') || document.createElement('script');
    $fbScript.src = 'https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v16.0';
    $fbScript.id = 'fb-script';
    $fbScript.addEventListener('load', () => {

        $fbScript.setAttributeNode(document.createAttribute('script-loaded'));

    });

    if (!$fbScript.isConnected) {
        document.body.append($fbScript);
    }

    const parseComments = () => {
        setTimeout(() => {
            FB?.XFBML?.parse();
        });
    }

    if ($fbScript.hasAttribute('script-loaded')) {
        parseComments();
    }

    return (
        <StyleFBComments>
            <div className="fb-comments" href={location.href}
                 data-width={"100%"}></div>
        </StyleFBComments>
    );


};

export default FBComments;

const StyleFBComments = styled.div`
  .fb-comments {
    aspect-ratio: auto;
  }
`;