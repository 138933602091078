import { useEffect, useState } from "react";
import { helpHttp } from "../helper/helpHttp";
import { toast } from "react-toastify";
import { useNavigate, useOutletContext } from "react-router-dom";

const useTest = ({ token, modulo, getModuleDetail }) => {
  const [reset, setReset] = useState(false);
  const [start, setStart] = useState(false);
  const [test, setTest] = useState(undefined);
  const [actualQuestion, setActualQuestion] = useState(undefined);
  const { post, get } = helpHttp();
  const initialState = { idEvaluacion: 1, respuestas: [] };
  const [response, setResponse] = useState(initialState);
  const [calificacion, setCalificacion] = useState(undefined);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [lastModule, setLastModule] = useState(null);
  const [handleLoaded] = useOutletContext();

  useEffect(() => {
    // Onpopstate observa el evento de back button y beforeunload el de refresh y el de cerrar con la cruz
    window.onpopstate = handleSendIncompleteTest;
    window.addEventListener("beforeunload", handleSendIncompleteTest);
    return () => {
      window.removeEventListener("beforeunload", handleSendIncompleteTest);
    };
  }, [calificacion, start, response]);

  useEffect(() => {
    get(`modulos/getModulosByCategoria/23`)
      .then((data) => {
        const ids = data.datos.map(({ id }) => Number(id));
        setLastModule(Math.max(...ids));
      })
      .catch((error) => {
        toast.error("Ha ocurrido un error");
      })
      .finally(() => {
        handleLoaded();
      });
  }, []);

  // Cuando se ingresa se cargan las respuestas que ya tenia el usuario y rellena las qeu faltan con 0
  const handleSendIncompleteTest = () => {
    const newResponse = { ...response };
    let lastResponse = newResponse?.respuestas?.length;
    if (!calificacion && start) {
      while (lastResponse < test?.length) {
        newResponse.respuestas.push({
          idPregunta: test[lastResponse].idPregunta,
          idRespuesta: "0",
        });
        lastResponse++;
      }
      post("evaluaciones/registrarEvaluacion", {
        body: newResponse,
        headers: { Authorization: token },
      });
    }
  };
  useEffect(() => {
    // Cuando las cantidad de respuestas es igual a la cantidad de preguntas se envian con el metodo Post
    response?.respuestas.length === test?.length &&
      post("evaluaciones/registrarEvaluacion", {
        body: response,
        headers: { Authorization: token },
      })
        .then((res) => {
          if (res.codigo === "400") {
            toast.error(res.mensaje);
            setOpen(false);
          } else {
            setCalificacion(res.datos);
            setResponse(initialState);
            setStart(false);
            toast.success("Se enviaron las respuestas correctamente");
            if (res.datos.aprobado) {
              setTimeout(() => {
                toast.success("Has aprobado la evaluación");
                if (lastModule === Number(modulo.id)) {
                  window.location.reload();
                } else {
                  navigate("/main/Experiencia%20Fleischmann", {
                    replace: true,
                  });
                }
              }, 2000);
            }
          }
        })
        .catch(() => toast.error("Ha ocurrido un error"));
  }, [response]);

  useEffect(() => {
    // Cuando se inicia el test se cargan las preguntas por el momento la evaluacion 1
    if (modulo) {
      get(`evaluaciones/getEvaluacionByModulo/${modulo.id}`, {
        headers: { Authorization: token },
      })
        .then((data) => {
          setTest(data.datos);
          setActualQuestion({
            number: 1,
            question: data.datos[0],
          });
        })
        .catch(() => toast.error("Ha ocurrido un error"));
    }
  }, [reset, modulo]);

  const handleResponse = (resp) => {
    setResponse({
      idEvaluacion: modulo.evaluacion,
      respuestas: [...response.respuestas, resp],
    });
    // if (actualQuestion?.number === test.length) {
    //   setStart(false)
    // }
    actualQuestion?.number < test.length &&
      setActualQuestion({
        number: actualQuestion.number + 1,
        question: test[actualQuestion.number],
      });
  };

  return {
    handleResponse,
    setReset,
    calificacion,
    actualQuestion,
    setCalificacion,
    test,
    start,
    setStart,
    open,
    setOpen,
  };
};

export default useTest;
