/* eslint-disable camelcase */
import React, { useState } from "react";
import { toast } from "react-toastify";
import { helpHttp } from "../../../helper/helpHttp";
import { useUser } from "../../../hooks/useUser";
import { PreSignUp } from "./PreSignUp";
import { VerificationCode } from "./VerificationCode";

const Fields = () => {
  const [emailToValidate, setEmailToValidate] = useState("");
  const [validateEmail, setValidateEmail] = useState(false);
  const [validateMessage, setValidateMessage] = useState(
    "Acabamos de enviar un correo electrónico para completar el registro"
  );

  const { post } = helpHttp();

  const onSubmit = (data) => {
    post("usuarios/registerValidation", {
      body: data,
    })
      .then((response) => {
        if (response.codigo === "200") {
          setEmailToValidate(data.correo);
          setValidateEmail(true);
        }

        if (
          response.mensaje ===
          "Ya existe un usuario con el correo enviado. Si aún no ha confirmado el usuario solicitar un nuevo código."
        ) {
          setEmailToValidate(data.correo);
          setValidateMessage(response.mensaje);
          setValidateEmail(true);
        }
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  return (
    <>
      {validateEmail ? (
        <VerificationCode
          emailToValidate={emailToValidate}
          setValidateEmail={setValidateEmail}
          validateMessage={validateMessage}
        />
      ) : (
        <PreSignUp onSubmit={onSubmit} />
      )}
    </>
  );
};

export default Fields;
