import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'
import Avatar from './Avatar'

const Profile = ({ user, logOutUser }) => {
  return (
    <ProfileContainer>
      <UserInfo>
        <Typography fontWeight='bold' variant='p' component='span'>
          {`${user.nombres} ${user.apellidos}`}
        </Typography>
        <Typography variant='p' component='p'>
          {user.correo}
        </Typography>
      </UserInfo>
      <Avatar user={user} logOutUser={logOutUser} />
    </ProfileContainer>
  )
}

const ProfileContainer = styled.div`
  color: white;
  display: flex;
  margin-left: auto;
  gap: 10px;
  height: 100%;
  align-items: center;
  text-align: right;
  justify-content: end;
  & img {
    padding: 5px;
    border-radius: 15px;
  }
`

const UserInfo = styled.div`
  display: none;
  text-transform: capitalize;
  @media (min-width: 600px) {
    display: block;
  }
`
export default Profile
