export const redeslink = [
  {
    src: '/images/redes_generico.jpg',
    link: 'https://www.facebook.com/watch/live/?ref=watch_permalink&v=968315837368053',
    title: "Panettone"
  },
  {
    src: '/images/redes_generico.jpg',
    link: 'https://www.facebook.com/watch/live/?ref=watch_permalink&v=581353092877531',
    title: "Chocolatoso de Uchuva hecho con nuestra Premezcla Bizcochuelo Fleischmann y la nueva Fleiscream"
  },
  {
    src: '/images/redes_generico.jpg',
    link: 'https://www.facebook.com/watch/live/?ref=watch_permalink&v=359007809008883',
    title: "Coberturas de chocolate"
  },
  {
    src: '/images/redes_generico.jpg',
    link: 'https://www.facebook.com/watch/live/?ref=watch_permalink&v=829419314329288',
    title: "Fraisier de Maracuyá"
  },
  {
    src: '/images/redes_generico.jpg',
    link: 'https://www.facebook.com/watch/live/?ref=watch_permalink&v=159790522837786',
    title: "Brazo de Reina"
  },
  {
    src: '/images/redes_generico.jpg',
    link: 'https://www.facebook.com/watch/live/?ref=watch_permalink&v=306647990864705',
    title: "Torta Tropical Con Ganache de Yerbabuena"
  },
  {
    src: '/images/redes_generico.jpg',
    link: 'https://www.facebook.com/watch/live/?ref=watch_permalink&v=1680656222122962',
    title: "Chiffón Ángel"
  },
]
