import styled from "@emotion/styled";
import { Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModalTest from "../ModalTest";
import { helpHttp } from "../../helper/helpHttp";
import { useUser } from "../../hooks/useUser";
import BackArrow from "../BackArrow";
import { useOutletContext, useParams } from "react-router-dom";
import { ExamToId } from "../../utils/urlToId";
import { toast } from "react-toastify";

const Test = () => {
  const { user } = useUser();
  const [handleLoaded] = useOutletContext();
  const { get } = helpHttp();
  const [modulo, setModulo] = useState(undefined);
  const [aprobado, setAprobado] = useState(false);
  const [puedeReintentar, setPuedeReintentar] = useState(true);
  const [isLastItem, setIsLastItem] = useState(false);

  const { subCategory } = useParams();
  const ID = ExamToId(subCategory);

  const getModuleDetail = () => {
    get(`modulos/getModuloById/${ID}`, {
      headers: { Authorization: user.token },
    })
      .then((data) => {
        setModulo(data.datos[0]);
        setAprobado(data.datos[0].aprobado === "1");
        setPuedeReintentar(data.datos[0].intentar === 1);
      })
      .catch(() => toast.error("Ha ocurrido un error"))
      .finally(() => {
        handleLoaded();
        handleLoaded();
      });
  };

  useEffect(() => {
    if (modulo) {
      get(`modulos/getModulosByCategoria/10`)
        .then((data) => {
          const ids = data.datos.map(({ id }) => Number(id));
          if (Number(modulo.id) === Math.max(...ids)) {
            setIsLastItem(true);
          }
        })
        .catch((error) => {
          toast.error("Ha ocurrido un error");
        })
        .finally(() => {
          handleLoaded();
        });
    }
  }, [modulo]);

  useEffect(() => {
    getModuleDetail();
  }, []);

  const handleRequestCertificate = () => {
    get("usuarios/getCertificateByUsuario")
      .then((response) => {
        if (response.codigo === "200") {
          toast.success(
            "Se ha solicitado su certificado. El administrador estará revisando su solicitud"
          );
        }
      })
      .catch((error) => {});
  };

  const renderButton = () => {
    if (!puedeReintentar) {
      return (
        <Typography className="aprobado" variant="title">
          Se ha superado el número de intentos máximos para la evaluación.
        </Typography>
      );
    }

    if (!aprobado) {
      return (
        <ModalTest
          token={user.token}
          modulo={modulo}
          getModuleDetail={getModuleDetail}
        />
      );
    }

    return (
      <Typography className="aprobado" variant="title">
        Ya has aprobado este modulo.
      </Typography>
    );
  };

  return (
    <StyledArticle>
      <BackArrow />
      <main>
        <Typography variant="title" component="h2">
          {modulo?.nombre}
        </Typography>
        <Typography variant="subtitle" fontWeight={700} component="h3">
          {modulo?.descripcion}
        </Typography>
        <Typography component="p">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut
          tristique ex. Phasellus sed vehicula nunc, non sagittis ex. Class
          aptent taciti sociosqu ad litora torquent per conubia nostra, per
          inceptos himenaeos. Aenean aliquam, justo non vestibulum rhoncus,
          velit nibh tincidunt magna, nec aliquam augue quam sed libero.
          Pellentesque sed laoreet urna, a fermentum mauris. Vestibulum at
          egestas metus. Nam malesuada augue est, a blandit nunc mollis a.
          Vivamus lacinia tempus ultrices. Praesent sit amet dui est. Duis enim
          urna, sagittis id volutpat vel, rutrum fringilla ex. Nam luctus arcu
          ac elit finibus luctus. Nulla facilisi. Cras tristique augue vel
          ornare convallis. Sed a auctor tellus, a congue nisl. Phasellus
          gravida vestibulum pharetra.
        </Typography>
        <StyledVideo>
          <iframe
            width="100%"
            height="100%"
            src="https://player.vimeo.com/video/679766408"
            frameBorder="0"
            allow="fullscreen; picture-in-picture"
            title="Premezclas Introducci&amp;amp;oacute;n"
            loading="lazy"
          />
        </StyledVideo>
        <StyledFooter>
          {renderButton()}
          {aprobado && isLastItem && (
            <Button
              color="secondary"
              onClick={handleRequestCertificate}
              variant="contained"
            >
              Solicitar certificado
            </Button>
          )}
        </StyledFooter>
      </main>
    </StyledArticle>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
const StyledArticle = styled.article`
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 30px;
  background-color: #f7f7f7;
  padding: 10px;
  @media (min-width: 768px) {
    padding: 30px;
  }
  > main {
    padding: 10px;
    @media (min-width: 768px) {
      padding: 50px;
    }
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .aprobado {
    font-size: 20px;
  }
`;
const StyledVideo = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  /* max-height: 400px; */
`;

export default Test;
