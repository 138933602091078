import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { helpHttp } from "../../helper/helpHttp";
import { useUser } from "../../hooks/useUser";
import SeminarioInfo from "../SeminarioInfo";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Seminario = ({ seminario, subscribed }) => {
  const { get, post } = helpHttp();
  const [open, setOpen] = useState(false);
  const [seminarioData, setSeminarioData] = useState(undefined);
  const [isseminarioInscrito, setSeminarioInscrito] = useState(false);
  const { user } = useUser();

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = (id) => {
    get("seminarios/getSeminarioById/" + id)
      .then((data) => {
        setSeminarioData(data.datos);
        setOpen(true);
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clickSeminario = () => {
    post(`usuarios/clicRegisterSeminarioUsuario/${seminario.id}`)
      .then(() => {})
      .catch(() => {});
  };

  const handleJoinToMeet = () => {
    clickSeminario();

    get("seminarios/getSeminarioById/" + seminario.id)
      .then(({ datos }) => {
        const date = new Date(datos.fecha);
        const today = new Date(Date.now());

        date.setDate(date.getDate() + 1);
        date.setHours(datos.hora.split(":")[0], datos.hora.split(":")[1]);

        const exactHour = new Date(date).setHours(date.getHours());

        const canJoin = moment(today).isSameOrAfter(exactHour);

        if (canJoin) {
          window.open(datos.link, "_blank", "noreferrer");
        } else {
          const dateString = moment(date).format("DD/MM/YYYY");
          const hoursString = moment(date).format("hh:mm A");

          toast.warn(
            `Aún no puedes ingresar. El seminario empezará el día: ${dateString} a las ${hoursString}`
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Ha ocurrido un error");
      });
  };

  const handleSuscribeSeminar = () => {
    const body = {
      id_usuario: user.id,
      id_seminario: seminario.id,
    };

    post("seminarios/suscribe", { body })
      .then((response) => {
        setSeminarioInscrito(true);

        if (response.codigo === "400") {
          toast(response.mensaje);
        } else {
          toast.success("Se ha inscrito correctamente");
        }
      })
      .catch(() => toast.error("Ha ocurrido un error"))
      .finally(() => {
        setOpenModal(false);
      });
  };

  useEffect(() => {
    if (subscribed) {
      setSeminarioInscrito(true);
    }
  }, [subscribed]);

  return (
    <>
      <StyledCard estado={seminario.estado} key={seminario.id}>
        <img src={seminario.imagen} alt={seminario.nombre} />
        <StyledCardContent>
          <Typography variant="subtitle" fontWeight={700}>
            {seminario.nombre}
          </Typography>
          <Typography variant="text" color={"black"} fontWeight={700}>
            Por {seminario.por}
          </Typography>
          <StyledDate>
            <Typography variant="caption" fontWeight={700}>
              Inicio: {seminario.fecha}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              Hora: {seminario.hora}hs
            </Typography>
          </StyledDate>
          <StyledButtons>
            {isseminarioInscrito ? (
              <Button
                color="primary"
                variant="contained"
                target="_blank"
                onClick={handleJoinToMeet}
              >
                Entrar
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setOpenModal(true)}
              >
                Inscribirse
              </Button>
            )}
            <Typography
              onClick={() => handleOpen(seminario.id)}
              variant="caption"
              fontWeight={700}
            >
              Leer más
            </Typography>
          </StyledButtons>
        </StyledCardContent>
      </StyledCard>
      <SeminarioInfo
        seminario={seminarioData}
        open={open}
        handleClose={handleClose}
      />
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirma tu inscripción
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Deseo inscribirme al seminario `}
            <b>{seminario.nombre}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenModal(false)}>
            Cancelar
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSuscribeSeminar}
          >
            Inscribirme
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Seminario;

const StyledCard = styled.div`
  color: #114c97;
  img {
    width: 100%;
    max-height: 200px;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 30px;
  }
`;

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  > span {
    line-height: 1;
  }
`;

const StyledButtons = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
  > button {
    text-transform: capitalize;
    flex: 2;
  }
  > span {
    flex: 1;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
`;
