import styled from "@emotion/styled";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { helpHttp } from "../../helper/helpHttp";
import { eliminarDiacriticosEs } from "../../utils/textConverter";
import { redeslink } from "./redeslinks";

const Redes = () => {
  const [handleLoaded] = useOutletContext();
  const [data, setData] = useState([]);
  const [datalinks, setDataLinks] = useState(redeslink);
  const { get } = helpHttp();
  useEffect(() => {
    get(`categorias/getDetailsCategoria/3`)
      .then((data) => {
        setData(data.datos);
      })
      .catch(() => toast.error("Ha ocurrido un error"))
      .finally(() => {
        handleLoaded();
      });
  }, []);

  return (
    <GridData>
      <StyledRedes>
        {data.map((e) => {
          return (
            <StyledCard key={e.id} id={eliminarDiacriticosEs(e.titulo, true)}>
              <StyledVideo>
                {e.url_video.trim() ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={e.url_video}
                    frameBorder="0"
                    loading="lazy"
                    allow="fullscreen; picture-in-picture"
                  />
                ) : (
                  <img
                    style={{ borderRadius: "3px" }}
                    width="100%"
                    src={e.imagen}
                  />
                )}
              </StyledVideo>
              <StyledInfo>
                <Typography component={"h3"} variant="title">
                  {e.titulo}
                </Typography>
                <div>
                  <Typography>{e.descripcion}</Typography>
                </div>
                {e.url_pdf && (
                  <div>
                    <StyledAnchor href={e.url_pdf} download={e.titulo}>
                      Descargar
                      <PictureAsPdfOutlinedIcon />
                    </StyledAnchor>
                  </div>
                )}
              </StyledInfo>
            </StyledCard>
          );
        })}
      </StyledRedes>
    </GridData>
  );
};

export default Redes;

const GridData = styled.div``;
const StyledRedes = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  gap: 10px;

  > img {
    width: 100px;
  }
  > span {
    padding: 20px 50px;
  }
`;

const StyledVideo = styled.div`
  aspect-ratio: 16/9;
  flex: 1;
  flex-basis: 360px;
  @media (min-width: 1100px) {
    max-width: 400px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  @media (min-width: 768px) {
    padding: 30px;
  }
  flex-wrap: wrap;
  width: 100%;
  gap: 10px 20px;
`;
const StyledInfo = styled.div`
  display: flex;
  flex: 2;
  flex-basis: 360px;
  flex-direction: column;
  gap: 10px;
  > h3 {
    font-size: 26px;
  }
`;

const StyledAnchor = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ffcc00;
  width: max-content;
  padding: 5px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-family: sans-serif;
  color: #114c97;
  margin-top: 15px;
`;
