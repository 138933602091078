import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../../../hooks/useUser";
import BackArrow from "../../../../components/BackArrow";
import { ModalQuiz } from "../ModalQuiz/ModalQuiz";
import Player from "@vimeo/player";

export const Quiz = ({ modulo, onCloseQuiz }) => {
  const { user } = useUser();
  const iframeRef = useRef(null);
  const [endedVideo, setEndedVideo] = useState(false);

  const renderQuiz = () => {
    if (modulo.aprobado === "0") {
      return (
        <ModalQuiz
          token={user.token}
          modulo={modulo}
          onCloseQuiz={onCloseQuiz}
        />
      );
    }

    return (
      <Typography className="aprobado" variant="title">
        Ya has aprobado este modulo.
      </Typography>
    );
  };

  useEffect(() => {
    const player = new Player(iframeRef.current);

    player.on("ended", () => {
      setEndedVideo(true);
    });

    return () => {
      player.off("ended");
    };
  }, []);

  return (
    <StyledArticle>
      <BackArrow />
      <main>
        <StyledVideo>
          <iframe
            ref={iframeRef}
            frameBorder={"none"}
            width="100%"
            height="100%"
            src={modulo.url_video}
            allow="fullscreen"
            title="Premezclas Introducci&amp;amp;oacute;n"
            loading="lazy"
          />
        </StyledVideo>
        {endedVideo && renderQuiz()}
      </main>
    </StyledArticle>
  );
};

const StyledArticle = styled.article`
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 30px;
  background-color: #f7f7f7;
  padding: 10px;
  @media (min-width: 768px) {
    padding: 30px;
  }
  > main {
    padding: 10px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .aprobado {
    font-size: 20px;
  }
`;
const StyledVideo = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
`;
