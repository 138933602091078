import React from "react";

export const CATEGORIES = {
  "Panadería y pastelería": "1",
  "Aprende con nuestro portafolio": "2",
  "Nuestras recetas": "3",
  Levadura: "4",
  Premezclas: "5",
  Margarinas: "6",
  Cremas: "7",
  Complementos: "8",
  Seminarios: "9",
  "Experiencia Fleischmann": "10",
  "Experiencia Express": "23",
};

const EXAMS = {
  "Lo nuevo de Fleischmann": "1",
  Tendencias: "2",
  "Panadería y pastelería saludable": "3",
  Ventas: "7",
};

export const urlToId = (uri) => {
  return CATEGORIES[uri];
};

export const ExamToId = (uri) => {
  return EXAMS[uri];
};
