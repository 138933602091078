import styled from "@emotion/styled";
import { Container } from "@mui/system";
import { usePrismicDocuments } from "@prismicio/react";
import React from "react";
import BlogItem from "../../components/BlogItem";

const BlogList = () => {
  const documents = usePrismicDocuments();
  const { results } = documents[0] || [];
  return (
    <StyledContainer>
      {results?.map((blog) => (
        <BlogItem key={blog.uid} blog={blog} />
      ))}
    </StyledContainer>
  );
};

export default BlogList;

const StyledContainer = styled(Container)`
  min-height: calc(100vh - 64px);
  max-width: 600px;
  padding-block: 150px 50px;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(300px, max-content)); */
  width: 100%;
  flex-wrap: wrap;
  max-width: 100%;
  display: flex;
  max-width: 1300px;
  gap: 40px;

  > a {
    max-width: 600px;
    min-width: 300px;
  }

  @media (max-width: 1287px) {
    max-width: 1100px;
    > a {
      max-width: 500px;
    }
  }

  @media (max-width: 1087px) {
    max-width: 900px;
    > a {
      max-width: 400px;
    }
  }

  @media (max-width: 887px) {
    > a {
      min-width: 100%;
    }
  }
`;
