/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";
import { helpHttp } from "../../../helper/helpHttp";
import { SignValidateSchema } from "./Schema";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import ModalResponse from "../../ModalResponse";
import { toast } from "react-toastify";

const Fields = () => {
  const navigate = useNavigate();
  const { post } = helpHttp();
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", resolver: yupResolver(SignValidateSchema) });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    post("usuarios/validateRestore", {
      body: data,
    })
      .then((response) => {
        response.codigo === "200" && navigate("../sign-in", { replace: true });
        setResponse(response);
        setOpen(true);
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField
        autoComplete="off"
        variant="standard"
        error={!!errors.correo?.message}
        helperText={errors.correo?.message}
        label="Correo electrónico"
        type="email"
        {...register("correo")}
      />
      <TextField
        autoComplete="no"
        variant="standard"
        error={!!errors.code?.message}
        helperText={errors.code?.message}
        label="Código de validación"
        {...register("codigo")}
      />
      <TextField
        variant="standard"
        error={!!errors.password?.message}
        helperText={errors.password?.message}
        label="Nueva contraseña"
        {...register("newPassword")}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="standard"
        error={!!errors.passwordConfirmation?.message}
        helperText={errors.passwordConfirmation?.message}
        label="Confirmar contraseña"
        {...register("passwordConfirmation")}
        type={showPassword ? "text" : "password"}
      />
      <StyledButton color="secondary" type="submit" variant="contained">
        Enviar
      </StyledButton>
      <ModalResponse
        open={open}
        response={response}
        handleClose={handleClose}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  margin-top: 30px;
  gap: 15px;
  flex-direction: column;
  p {
    font-size: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  align-self: center;
`;

export default Fields;
