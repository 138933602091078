import styled from '@emotion/styled'
import React from 'react'
import Carousel from '../../components/Carousel'
import Introduction from '../../components/Introduction'
const Home = () => {
  return (
    <StyledContainer>
      <Carousel />
      <Introduction />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
min-height: 100vh;
`

export default Home
