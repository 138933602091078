import React from "react";

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

export const Register = ({ onAccept, availablePlaces, module }) => {
  let places = "cupos";
  if (availablePlaces === 1) {
    places = "cupo";
  }

  return (
    <AvailablePlaces>
      <Typography variant="title" component="h2">
        {module.nombre}
      </Typography>
      <div className="places_txt">
        <Typography>Actualmente no estás inscrito</Typography>
        <Typography>
          Quedan
          <b>{` ${availablePlaces} ${places} `}</b>
          disponibles
        </Typography>
      </div>
      <Button
        onClick={onAccept}
        color="secondary"
        size="large"
        variant="contained"
        sx={PresencialesBtn}
        disabled={availablePlaces === 0}
      >
        Iniciar trivia
      </Button>
    </AvailablePlaces>
  );
};

const AvailablePlaces = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .places_txt {
    text-align: center;
  }
`;

const PresencialesBtn = {
  fontSize: "20px",
  textTransform: "none",
  width: "350px",
  height: "50px",
};
