import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TestFields } from "../Forms/Test/TestFields";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import useTest from "../../hooks/useTest";
import TestResult from "../TestResult";

const ModalTest = ({ modulo, token }) => {
  const {
    handleResponse,
    setReset,
    test,
    calificacion,
    actualQuestion,
    setCalificacion,
    start,
    setStart,
    open,
    setOpen,
  } = useTest({ token, modulo });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" onClick={handleClickOpen} variant="contained">
        Hacer evaluación
      </Button>
      <Dialog disableScrollLock open={open}>
        {calificacion ? (
          <TestResult calificacion={calificacion} />
        ) : start ? (
          <>
            <StyledDialogTitle component="div">
              <Typography variant="link" color="primary">
                {actualQuestion?.number}/{test?.length}
              </Typography>
              <Typography variant="title" component="h2">
                {modulo?.nombre}
              </Typography>
              <Typography>{actualQuestion?.question?.pregunta}</Typography>
            </StyledDialogTitle>
            <StyledDialogContent>
              <TestFields
                last={actualQuestion?.number === test?.length}
                question={actualQuestion?.question}
                handleResponse={handleResponse}
              />
            </StyledDialogContent>
          </>
        ) : (
          <>
            <StyledDialogTitle component="div">
              <Typography variant="title" component="h2">
                Antes de comenzar
              </Typography>
            </StyledDialogTitle>
            <StyledDialogContent>
              <Typography>
                Si se cierra o refresca el navegador, durante la ejecucion del
                examen las respuestas seran enviadas de todos modos!{" "}
              </Typography>
            </StyledDialogContent>
          </>
        )}
        <DialogActions>
          {calificacion?.aprobado === false && (
            <Button
              onClick={() => {
                setReset((prev) => !prev);
                setCalificacion(undefined);
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Reintentar
            </Button>
          )}
          {!start && !calificacion && (
            <Button
              onClick={() => {
                setStart(true);
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Continuar
            </Button>
          )}
          {(calificacion || !actualQuestion || !start) && (
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              autoFocus
            >
              Cerrar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    gap: 20px;
  }
  gap: 10px;
  > h2 {
    font-size: 1.5rem;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  overflow: visible;
`;
export default ModalTest;
