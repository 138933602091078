import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { helpHttp } from "../../helper/helpHttp";
import { Quiz } from "./Components/Quiz/Quiz";
import { Register } from "./Components/Register/Register";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

const EXPERIENCIA_EXPRESS = "23";

export const ExperienciaExpress = () => {
  const User = useSelector((data) => data.user.user);
  const [handleLoaded] = useOutletContext();
  const { get, post } = helpHttp();

  const [data, setData] = useState(undefined);
  const [subscribed, setSubscribed] = useState(false);
  const [availablePlaces, setAvailablePlaces] = useState(0);

  const handleRegisterUserToModule = () => {
    const body = {
      id_usuario: User.id,
      id_modulo: data.id,
    };

    post("modulos/suscribe", { body }).then((response) => {
      const mensaje = response?.mensaje;

      if (mensaje === "No hay cupos disponibles!!!.") {
        setSubscribed(false);
        setAvailablePlaces(0);
        /* MOSTRAR MENSAJE DE ERROR CON NO HAY CUPOS DISPONIBLES */
      } else {
        setSubscribed(true);
      }
    });
  };

  let available = data?.estado === "habilitado" && data?.aprobado === "0";

  const getModulesByCategory = () => {
    get(`modulos/getModulosExpressByCategoria/${EXPERIENCIA_EXPRESS}`)
      .then((data) => {
        setSubscribed(data.datos[0].inscrito === "1");
        setData(data.datos[0]);
      })
      .catch(() => toast.error("Ha ocurrido un error"))
      .finally(() => {
        handleLoaded();
      });
  };

  const handleCloseQuiz = () => {
    getModulesByCategory();
  };

  useEffect(() => {
    if (data !== undefined) {
      get(`modulos/getCuposByModulo/${data.id}`)
        .then((response) => {
          const places = response.datos.disponibles;
          setAvailablePlaces(parseInt(places));
        })
        .finally(() => {});
    }
  }, [data]);

  useEffect(() => {
    getModulesByCategory();
  }, []);

  if (!available) {
    return (
      <Typography variant="title" component="h2">
        No tienes evaluaciones disponibles
      </Typography>
    );
  }

  if (!subscribed) {
    return (
      <Register
        onAccept={handleRegisterUserToModule}
        availablePlaces={availablePlaces}
        module={data}
      />
    );
  }

  return (
    <ExperienciasExpressContainer>
      <Quiz modulo={data} onCloseQuiz={handleCloseQuiz} />
    </ExperienciasExpressContainer>
  );
};

const ExperienciasExpressContainer = styled.div`
  width: 90%;
  text-align: center;
  color: #114c97;
`;
